/* ============
 * Getters for the user module
 * ============
 *
 * The getters that are available on the
 * user module.
 */

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _isEmpty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import _get from 'lodash/get';

export default {
  loaded(state) {
    return (!_isEmpty(state.vendor) && !_isEmpty(state.account));
  },

  licensingModels(state) {
    return _filter(state.plan, (v, k) => (/^LM_.*/gm).test(k));
  },

  isDemo(state) {
    const { vendor: { number } } = state;
    return (number === 'VDEMO');
  },

  // Plan limits and features
  isProductsLimitsReached(state) {
    const { plan, stats: { products: { active: used } } } = state;

    const quota = _get(plan, 'PRODUCTS.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isLicenseesLimitsReached(state) {
    const { plan, stats: { licensees: { active: used } } } = state;

    const quota = _get(plan, 'LICENSEES.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isLicensesLimitsReached(state) {
    const { plan, stats: { licenses: { active: used } } } = state;

    const quota = _get(plan, 'LICENSES.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isNotificationsLimitsReached(state) {
    const { plan, stats: { notifications: { active: used } } } = state;

    const quota = _get(plan, 'NOTIFICATIONS.quota', 0);

    if (quota === -1) {
      return false;
    }

    return used >= quota;
  },

  isEUVATSupportAvailable(state) {
    return _get(state.plan, 'EUVAT.valid', false);
  },

  isShopCustomizationAvailable(state) {
    return _get(state.plan, 'SHOPCUST.valid', false);
  },

  isPaypalPaymentMethodAvailable(state) {
    return _get(state.plan, 'PSP_PAYPAL.valid', false);
  },

  isStripePaymentMethodAvailable(state) {
    return _get(state.plan, 'PSP_STRIPE.valid', false);
  },

  currency(state) {
    return _get(state, 'vendor.currency', 'EUR');
  },
};
