/* ============
 * Actions for the account module
 * ============
 *
 * The actions that are available on the
 * account module.
 */
import dayJs from 'dayjs';

// NetLicensing js library
import {
  ProductService,
  ProductModuleService,
  LicenseTemplateService,
  LicenseeService,
  LicenseService,
  NotificationService,
} from 'netlicensing-client';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _get from 'lodash/get';
import _each from 'lodash/each';
import _castArray from 'lodash/castArray';
import _fromPairs from 'lodash/fromPairs';

// utils
import itemToObject from '@/utils/itemToObject';
import dotObject from '@/utils/dotObject';

// api
import { fetchVendor, fetchAccount } from '@/api/auth';

const STATS_CACHE_LIFETIME = 5; // seconds

/**
 * Get account from API by auth.token
 *
 * @param dispatch
 * @param commit
 * @param rootState
 * @param rootGetters
 * @returns {Promise<void>}
 */
export const find = async ({ commit, rootState, rootGetters }) => {
  if (!rootGetters['auth/authenticated']) {
    throw new Error('Unable to find account, user is not logged in');
  }

  const context = rootGetters['auth/context'];

  const { auth: { token: { vendorNumber } } } = rootState;

  const query = { filter: 'validate=true;vendorData=true' };

  const { data: vendorData } = await fetchVendor(context, vendorNumber, query);
  const { data: accountData } = await fetchAccount(rootGetters['auth/context']);

  const { Vendor: vendor, ProductModuleValidation: plan = {} } = itemToObject(_get(vendorData, 'items.item'));
  const { Account: account } = itemToObject(_get(accountData, 'items.item'));

  commit('setVendor', vendor);
  commit('setAccount', account);
  commit('setPlan', plan);
};

/**
 * Fetch user statistic
 *
 * @param commit
 * @param rootGetters
 * @param keys
 * @param force
 * @returns {Promise<void>}
 */
export const fetchStats = async ({ commit, rootGetters }, keys, force = false) => {
  if (!rootGetters['auth/authenticated']) {
    throw new Error('Unable to fetch statistic, user is not logged in');
  }

  const promises = [];

  const storage = JSON.parse(localStorage.getItem('STATS') || '{}');

  _castArray(keys).forEach((v) => {
    const [entity, group = 'total'] = v.split('.');

    const filters = { page: 0, items: 1 };

    if (group === 'active') {
      filters.active = true;
    } else if (group === 'inactive') {
      filters.active = false;
    }

    const key = `${entity}.${group}`;
    const { expires } = storage[key] || {};

    if (!force && (expires && dayJs(expires).isAfter(dayJs()))) {
      return;
    }

    let service = null;

    switch (entity) {
      case 'products': {
        service = ProductService;
        break;
      }
      case 'modules': {
        service = ProductModuleService;
        break;
      }
      case 'templates': {
        service = LicenseTemplateService;
        break;
      }
      case 'licensees': {
        service = LicenseeService;
        break;
      }
      case 'licenses': {
        service = LicenseService;
        break;
      }
      case 'notifications': {
        service = NotificationService;
        break;
      }
      default: {
        throw new Error('Unknown entity type');
      }
    }

    if (service) {
      storage[key] = storage[key] || {};

      storage[key].expires = dayJs().add(STATS_CACHE_LIFETIME, 'second').toISOString();

      promises.push(
        service
          .list(rootGetters['auth/context'], filters)
          .then((list) => [key, parseInt(list.getTotalItems() || 0, 10)]),
      );
    }
  });

  localStorage.setItem('STATS', JSON.stringify(storage));

  let stats = {};

  try {
    stats = await Promise.all(promises);
  } catch (e) {
    localStorage.removeItem('STATS');
    throw e;
  }

  commit('setStats', _fromPairs(stats));
};

export const updateStats = ({ commit }, statistic) => {
  const storage = JSON.parse(localStorage.getItem('STATS') || '{}');
  const dotStats = dotObject(statistic);

  _each(dotStats, (v, k) => {
    storage[k] = storage[k] || {};
    storage[k].expires = dayJs().add(STATS_CACHE_LIFETIME, 'second').toISOString();
  });

  localStorage.setItem('STATS', JSON.stringify(storage));

  commit('setStats', statistic);
};

export const resetStats = ({ commit }) => {
  localStorage.removeItem('STATS');
  commit('resetStats');
};

/**
 * Reset all user data to defaults
 *
 * @param commit
 */
export const reset = ({ commit }) => {
  commit('reset');
};

export default {
  find,
  reset,
  fetchStats,
  updateStats,
  resetStats,
};
