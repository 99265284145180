/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _each from 'lodash/each';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';

export default (object) => {
  const dotObject = {};

  const toDotObject = (ob, keyPiece = '') => {
    _each(ob, (v, k) => {
      const key = (keyPiece) ? `${keyPiece}.${k}` : k;

      if (!_isEmpty(ob[k]) && _isObject(ob[k])) {
        toDotObject(ob[k], key);
        return;
      }

      dotObject[key] = ob[k];
    });
  };

  if (object) {
    toDotObject(object);
  }

  return dotObject;
};
