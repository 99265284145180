import authenticate from './authenticate';
import authenticateByToken from './authenticateByToken';
import redirectIfAuthenticated from './redirectIfAuthenticated';
import findUser from './findUser';
import authorize from './authorize';
import loadDynamicEnv from './loadDynamicEnv';

export default {
  /**
   * The application's global HTTP middleware stack.
   * These middleware are run during every request to your application.
   */
  global: {
    before: [
      loadDynamicEnv,
    ],
    after: [],
  },

  /**
   * The application's route middleware groups.
   */
  group: {
    auth: [
      authenticateByToken,
      authenticate,
      findUser,
    ],
  },

  /**
   * The application's route middleware.
   * These middleware may be assigned to groups or used individually.
   */
  route: {
    guest: redirectIfAuthenticated,
    can: authorize,
  },
};
