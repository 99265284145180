/* ============
 * State of the auth module
 * ============
 *
 * The initial state of the auth module.
 */

import LongTermStorage from '@/utils/LongTermStorage';

export default {
  token: LongTermStorage.get('NLIC_APIKEY_SESSION_TOKEN', null),
};
