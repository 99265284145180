/* ============
 * State of the account module
 * ============
 *
 * The initial state of the account module.
 */

export const defaults = () => ({
  vendor: {},
  account: {
    policy: {},
  },
  plan: {
    activePricingPlans: [],
  },

  usedQuantity: {
    products: 0,
    modules: 0,
    licensees: 0,
    licenses: 0,
  },

  stats: {
    products: {
      total: 0,
      active: 0,
      inactive: 0,
    },
    modules: {
      total: 0,
      active: 0,
      inactive: 0,
    },
    templates: {
      total: 0,
      active: 0,
      inactive: 0,
    },
    licensees: {
      total: 0,
      active: 0,
      inactive: 0,
    },
    licenses: {
      total: 0,
      active: 0,
      inactive: 0,
    },
    notifications: {
      total: 0,
      active: 0,
      inactive: 0,
    },
  },
});

export default defaults();
