import btoa from 'btoa';
import atob from 'atob';

/**
 * Lodash functions
 * @see https://lodash.com/docs
 */
import _isNull from 'lodash/isNull';
import _isObject from 'lodash/isObject';

const longTermStorageKey = 'NLIC_LONG_TERM_STORAGE';

export default {
  get(key, def) {
    if (typeof localStorage === 'undefined') {
      return def;
    }

    let value = localStorage.getItem(key);

    if (!value) {
      return def;
    }

    const storage = (localStorage.getItem(longTermStorageKey))
      ? JSON.parse(localStorage.getItem(longTermStorageKey))
      : {};
    const { object = true, secured = true } = (storage && storage[key]) ? storage[key] : {};

    if (secured) {
      value = atob(value);
    }

    if (object) {
      value = JSON.parse(value);
    }

    return value;
  },

  set(key, value, secured = true) {
    if (key === longTermStorageKey) {
      throw Error(`${key} is reserved`);
    }

    if (typeof localStorage !== 'undefined') {
      const object = (_isNull(value) || _isObject(value));

      let v = value;

      if (object) {
        v = JSON.stringify(v);
      }

      if (secured) {
        v = btoa(v);
      }

      const storage = (localStorage.getItem(longTermStorageKey))
        ? JSON.parse(localStorage.getItem(longTermStorageKey))
        : {};

      storage[key] = { object, secured };

      localStorage.setItem(longTermStorageKey, JSON.stringify(storage));
      localStorage.setItem(key, v);
    }
  },

  has(key) {
    return !!(typeof localStorage !== 'undefined' && localStorage.getItem(key));
  },

  remove(key) {
    if (typeof localStorage !== 'undefined' && localStorage.removeItem) {
      const storage = (localStorage.getItem(longTermStorageKey))
        ? JSON.parse(localStorage.getItem(longTermStorageKey))
        : {};

      delete storage[key];

      localStorage.setItem(longTermStorageKey, JSON.stringify(storage));
      localStorage.removeItem(key);
    }
  },
};
